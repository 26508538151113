import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
					<path d="M2591 3705 c59 -7 117 -16 130 -20 13 -4 21 -4 17 0 -14 15 -125 35
-188 34 -61 -1 -59 -2 41 -14z"/>
					<path d="M2378 3703 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M2382 3680 c-130 -18 -285 -84 -405 -172 -102 -76 -193 -184 -260
-311 -14 -25 -28 -43 -32 -39 -5 4 -5 -3 -2 -16 3 -12 1 -20 -4 -16 -8 5 -13
-12 -12 -43 1 -7 -3 -11 -7 -8 -5 3 -18 -39 -30 -92 -20 -84 -22 -116 -17
-233 8 -199 57 -343 162 -480 58 -76 89 -98 41 -30 -34 47 -27 42 48 -37 183
-194 381 -283 626 -283 131 0 211 15 326 59 190 73 380 249 466 431 17 36 35
67 39 70 4 3 7 14 6 25 -1 12 3 19 11 17 9 -1 10 6 6 24 -5 21 -4 25 6 19 10
-6 12 -3 8 13 -4 12 -3 22 2 22 14 0 30 115 30 225 0 183 -66 391 -157 493
-38 44 -43 39 -8 -8 14 -19 25 -39 25 -44 0 -6 -12 7 -27 29 -116 175 -311
314 -510 366 -87 22 -244 31 -331 19z m288 -130 c13 -9 12 -13 -9 -30 -28 -24
-51 -26 -51 -4 0 12 -7 14 -32 9 -40 -8 -103 -39 -103 -50 0 -4 28 -16 62 -25
34 -9 69 -22 77 -29 22 -19 30 -70 15 -98 -27 -50 -120 -75 -182 -49 -47 19
-51 55 -9 84 32 23 98 40 88 23 -4 -6 -14 -11 -22 -11 -25 0 -74 -30 -74 -45
0 -34 146 -12 178 27 22 27 3 45 -66 65 -105 30 -117 83 -29 124 45 20 133 25
157 9z m-370 -147 c27 -70 54 -127 60 -125 6 1 13 -4 16 -12 4 -11 31 -13 130
-11 68 1 127 -1 130 -6 2 -5 23 -13 46 -20 23 -6 48 -17 56 -26 12 -12 27 -14
66 -8 28 3 51 7 51 8 11 25 81 107 91 107 18 0 18 -11 -1 -36 -8 -10 -15 -22
-15 -26 0 -13 65 4 85 23 52 46 125 48 125 3 0 -26 -20 -54 -39 -54 -15 0 -13
11 4 34 19 25 19 36 1 36 -16 0 -76 -47 -76 -60 0 -4 15 -17 33 -28 31 -18 36
-18 71 -4 47 18 56 10 21 -20 l-27 -23 21 -19 c20 -19 22 -19 61 -1 28 13 44
15 51 8 7 -7 -14 -25 -68 -56 -43 -25 -93 -58 -112 -72 -35 -25 -71 -33 -71
-16 0 5 21 23 48 40 83 55 87 58 72 76 -19 23 -36 19 -91 -25 -65 -51 -104
-55 -61 -7 16 20 16 20 -3 12 -11 -4 -36 -10 -55 -13 -19 -2 -47 -13 -62 -24
-16 -11 -33 -16 -38 -13 -24 15 -40 -17 -40 -81 l0 -64 33 0 c28 0 35 -6 57
-48 l25 -47 85 -5 c47 -3 93 -2 102 2 9 5 19 4 23 -2 3 -5 23 -10 43 -10 34 0
38 -3 40 -27 1 -14 -5 -35 -15 -45 -13 -14 -13 -18 -2 -18 10 -1 8 -5 -5 -15
-11 -8 -14 -15 -8 -15 20 0 13 -55 -17 -128 -62 -153 -197 -297 -341 -365 -47
-22 -92 -45 -100 -52 -35 -30 -264 -46 -311 -21 -14 7 -52 21 -85 31 -127 39
-222 102 -323 211 -98 108 -171 279 -171 403 l0 51 110 0 c104 0 110 1 110 20
0 11 17 39 38 63 58 63 63 117 11 117 -11 0 -19 5 -19 10 0 6 -10 10 -22 10
-34 0 -68 -11 -68 -22 0 -5 5 -6 11 -2 21 12 -1 -69 -27 -103 -14 -18 -28 -32
-32 -31 -4 1 -16 -3 -26 -7 -13 -6 -23 -3 -30 6 -6 8 -50 39 -98 68 -74 45
-90 52 -103 41 -21 -17 -29 -6 -15 20 6 12 9 29 5 38 -3 9 -3 13 1 10 4 -4 19
19 35 52 16 35 35 60 44 60 21 0 19 -12 -16 -81 -17 -34 -29 -62 -27 -63 2 0
17 -9 35 -18 17 -10 35 -18 40 -18 4 0 19 23 32 50 25 49 51 66 51 33 0 -10
-9 -31 -20 -48 -27 -41 -25 -62 10 -80 43 -22 49 -20 71 33 12 26 27 61 35 79
8 17 12 38 9 45 -3 8 -1 27 5 42 12 33 -1 56 -31 56 -24 0 -26 -42 -4 -64 11
-11 12 -17 4 -22 -15 -9 -39 23 -39 51 0 32 28 65 56 65 17 0 41 -17 74 -51
28 -29 53 -49 57 -46 3 4 -13 35 -36 70 -45 70 -48 77 -26 77 8 0 15 9 15 19
0 10 7 21 15 25 14 5 45 -18 45 -34 0 -15 -24 -24 -28 -12 -2 7 -8 12 -14 12
-13 0 0 -38 26 -75 21 -30 38 -37 86 -36 31 1 40 11 30 36 -7 17 -9 17 -9 3
-1 -29 -17 -20 -34 20 -32 74 -87 244 -81 253 8 14 39 -6 142 -89 51 -41 94
-73 96 -71 5 5 -27 103 -53 162 -12 27 -18 53 -15 59 9 15 12 8 64 -129z m414
-15 c-3 -18 -7 -41 -9 -50 -5 -26 8 -22 55 17 23 19 44 35 46 35 3 0 1 -30 -2
-67 l-7 -67 42 34 c62 51 69 55 77 47 4 -4 -3 -15 -17 -24 -13 -9 -42 -34 -63
-55 -37 -36 -83 -55 -79 -33 12 81 14 105 6 105 -11 0 -53 -55 -53 -70 0 -6
-5 -8 -11 -4 -6 3 -15 0 -20 -7 -16 -27 -19 -1 -8 84 10 74 14 87 30 87 15 0
18 -6 13 -32z m323 5 c5 -14 -26 -45 -41 -40 -13 5 -8 39 7 49 15 9 30 5 34
-9z m242 -169 c16 -37 9 -39 -11 -3 -9 16 -14 29 -9 29 4 0 13 -12 20 -26z
m25 -60 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m-107 -130 c2
-6 -12 -30 -31 -54 -40 -51 -35 -60 34 -60 45 0 80 -13 68 -25 -4 -4 -46 -2
-95 4 -48 6 -128 11 -176 11 -56 0 -86 4 -82 10 3 6 42 10 85 10 l78 0 49 59
c46 57 63 67 70 45z m-1458 -664 c11 -24 11 -24 -3 -6 -9 11 -16 24 -16 30 0
12 5 7 19 -24z"/>
					<path d="M2121 3348 c28 -72 60 -138 53 -110 -7 27 12 30 21 2 6 -18 9 -19 26
-6 16 10 29 11 60 3 28 -7 47 -6 62 1 22 10 22 11 -4 11 -17 1 -64 29 -127 76
-55 41 -103 75 -106 75 -3 0 4 -24 15 -52z"/>
					<path d="M2940 3220 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
					<path d="M3040 3161 c0 -14 5 -19 15 -14 17 6 15 25 -3 31 -7 2 -12 -5 -12
-17z"/>
					<path d="M2272 3130 c-50 -11 -57 -23 -39 -60 21 -42 12 -199 -12 -208 -20 -8
-61 -44 -61 -54 0 -13 28 -9 33 5 4 10 7 10 18 0 16 -15 49 -17 49 -3 0 6 23
10 51 10 69 0 139 19 139 37 0 12 -15 14 -82 11 l-83 -3 -3 42 -3 42 63 3 c50
2 63 7 66 20 3 14 -7 17 -60 20 -58 3 -63 5 -66 27 -2 12 -1 34 2 47 6 22 11
24 70 24 43 0 67 5 75 14 9 11 9 16 -1 22 -17 11 -115 13 -156 4z"/>
					<path d="M2661 3130 c-57 -13 -80 -31 -91 -69 -14 -52 6 -76 75 -92 31 -7 63
-18 73 -25 16 -11 15 -14 -2 -33 -33 -37 -156 -61 -156 -31 0 12 46 40 72 43
24 4 38 27 16 27 -57 0 -128 -48 -128 -86 0 -30 39 -54 85 -54 116 0 183 73
145 158 -18 40 -37 49 -122 57 -28 3 -29 10 -5 32 30 29 97 42 97 19 0 -9 6
-16 13 -16 20 0 37 21 37 46 0 34 -35 41 -109 24z"/>
					<path d="M1982 3089 c3 -43 21 -63 31 -35 8 20 -6 69 -22 74 -9 3 -11 -8 -9
-39z"/>
					<path d="M2032 3065 c-1 -14 3 -25 8 -25 6 0 10 11 10 25 0 14 -4 25 -8 25 -4
0 -9 -11 -10 -25z"/>
					<path d="M3120 2771 c0 -5 11 -11 24 -13 17 -4 23 -1 19 9 -6 14 -43 18 -43 4z" />
					<path d="M2385 2731 c-47 -22 -104 -78 -70 -68 106 31 103 30 129 13 14 -9 30
-34 37 -56 10 -33 17 -40 38 -40 20 0 23 -2 11 -10 -20 -13 -26 -12 -44 6 -15
14 -16 13 -16 -10 0 -14 -4 -26 -10 -26 -5 0 -7 13 -4 29 4 16 1 32 -5 35 -17
11 -13 26 4 20 11 -4 13 -2 10 8 -7 16 -51 18 -48 3 1 -6 -19 -10 -43 -10 -24
0 -44 3 -44 7 0 15 -30 8 -49 -11 -16 -16 -17 -21 -4 -45 17 -32 7 -51 -10
-21 -11 19 -11 19 -18 -5 -12 -43 -10 -87 7 -137 33 -101 129 -163 249 -163
62 0 78 4 125 33 40 23 64 47 87 87 29 47 33 63 33 125 0 46 -7 84 -19 111
-22 49 -84 111 -130 130 -49 21 -167 18 -216 -5z m2 -117 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
					<path d="M2011 2699 c-27 -16 -31 -23 -20 -29 8 -6 18 -5 23 0 14 14 46 13 46
-1 0 -6 -9 -13 -20 -16 -11 -3 -17 -9 -14 -14 8 -13 -4 -11 -30 6 -20 13 -25
14 -35 1 -8 -10 -9 -16 -1 -21 16 -10 12 -25 -6 -25 -14 0 -16 -8 -12 -45 10
-86 63 -129 157 -128 62 2 94 21 122 73 72 133 -81 278 -210 199z m66 -61 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m33 -28 c0 -5 -4 -10 -9 -10
-6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z"/>
					<path d="M2853 2693 c-20 -8 -15 -33 6 -33 12 0 23 -11 30 -30 5 -16 17 -30
25 -30 9 0 16 -4 16 -10 0 -6 -9 -6 -24 0 -17 6 -26 5 -30 -3 -3 -7 -3 0 -1
15 2 19 -2 27 -15 31 -11 3 -21 0 -23 -6 -4 -13 -37 -2 -37 13 0 5 7 10 16 10
9 0 12 5 8 12 -10 16 -44 -28 -44 -58 0 -13 -4 -24 -10 -24 -14 0 -13 -66 2
-93 27 -51 72 -77 133 -77 62 0 93 15 123 60 20 29 23 45 20 90 -6 89 -58 141
-140 139 -23 0 -49 -3 -55 -6z"/>
					<path d="M1844 3458 l-19 -23 23 19 c21 18 27 26 19 26 -2 0 -12 -10 -23 -22z" />
					<path d="M1870 3447 c-14 -12 -32 -31 -40 -42 -8 -11 5 -2 28 20 38 35 47 46
40 45 -2 -1 -14 -11 -28 -23z"/>
					<path d="M1750 3318 c-69 -85 -164 -326 -147 -370 3 -7 6 -3 6 8 1 31 49 170
78 229 14 28 43 76 65 108 47 70 45 85 -2 25z"/>
					<path d="M3381 2584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M3355 2513 c-30 -80 -70 -155 -120 -221 -53 -73 -56 -94 -3 -30 43
53 111 177 132 242 22 63 14 71 -9 9z"/>
					<path d="M3165 2202 c-5 -4 -21 -21 -35 -37 -22 -27 -21 -26 13 4 33 32 42 41
35 41 -2 0 -7 -4 -13 -8z"/>
					<path d="M3080 2120 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
					<path d="M3085 2095 c-22 -19 -35 -35 -29 -35 5 0 25 16 44 35 43 44 37 44
-15 0z"/>
					<path d="M3010 2040 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z" />
					<path d="M2829 1955 c-97 -41 -211 -60 -354 -59 -71 0 -116 -3 -100 -7 55 -12
241 -9 329 6 88 16 151 40 175 69 16 19 19 20 -50 -9z"/>
					<path d="M1634 1756 c-18 -8 -35 -19 -38 -25 -13 -21 13 -49 54 -56 67 -11 50
-58 -25 -66 -17 -2 -36 0 -43 4 -6 5 -12 4 -12 -2 0 -18 38 -32 67 -25 49 12
74 35 71 63 -2 21 -12 29 -48 41 -57 18 -56 18 -41 33 6 6 31 12 54 12 31 0
42 4 42 15 0 20 -42 23 -81 6z"/>
					<path d="M1748 1703 c-9 -17 -13 -35 -9 -38 3 -4 6 -14 7 -23 3 -46 5 -53 14
-47 6 3 10 21 10 40 0 18 6 36 13 38 9 4 9 8 1 14 -7 4 -13 17 -16 28 -3 17
-6 15 -20 -12z"/>
					<path d="M1997 1709 c-11 -39 -8 -121 3 -114 6 3 10 22 10 41 0 24 5 34 15 34
8 0 15 5 15 10 0 6 -5 10 -10 10 -6 0 -14 10 -19 22 -8 21 -8 21 -14 -3z"/>
					<path d="M2695 1700 c-10 -11 -26 -22 -36 -25 -15 -5 -18 -16 -17 -66 1 -49 3
-57 14 -48 8 6 14 16 14 21 0 5 10 18 23 28 17 14 22 29 22 64 0 25 0 46 -1
46 0 0 -9 -9 -19 -20z m5 -49 c0 -10 -7 -24 -15 -31 -21 -17 -39 1 -30 29 8
27 45 28 45 2z"/>
					<path d="M1835 1690 c-3 -5 1 -10 9 -10 24 0 19 -25 -8 -40 -29 -15 -41 -39
-27 -53 6 -6 9 -3 8 8 -2 19 28 43 39 32 4 -3 1 -13 -6 -22 -10 -13 -9 -15 9
-15 18 0 21 6 21 43 0 52 -27 86 -45 57z"/>
					<path d="M1910 1631 c0 -60 16 -61 22 -1 2 28 7 34 22 32 11 -2 16 2 14 9 -2
7 -16 13 -31 13 -26 2 -27 0 -27 -53z"/>
					<path d="M2150 1673 c0 -12 -6 -24 -12 -26 -8 -3 -6 -6 5 -6 22 -1 34 26 18
42 -8 8 -11 6 -11 -10z"/>
					<path d="M2849 1683 c-1 -4 0 -12 1 -18 2 -5 2 -25 1 -42 -1 -18 3 -33 9 -33
13 0 13 87 0 95 -5 3 -11 3 -11 -2z"/>
					<path d="M2915 1666 c-19 -19 -23 -30 -16 -47 4 -13 13 -25 20 -27 9 -3 9 2 0
22 -9 20 -9 29 1 41 29 35 62 4 42 -41 -12 -26 -4 -32 16 -12 18 18 15 53 -6
71 -24 22 -28 22 -57 -7z"/>
					<path d="M3242 1674 c-38 -26 -25 -94 18 -94 44 0 68 80 31 100 -25 13 -22 13
-49 -6z m45 -20 c7 -18 -12 -64 -26 -64 -19 0 -32 39 -21 60 13 24 38 26 47 4z"/>
					<path d="M1811 1664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M2103 1654 c-21 -33 -8 -58 34 -62 22 -2 32 2 37 15 6 14 5 16 -5 6
-17 -17 -48 -16 -55 0 -3 8 0 26 5 40 13 34 6 34 -16 1z"/>
					<path d="M2260 1666 c0 -9 -4 -16 -8 -16 -5 0 -18 -9 -30 -20 -23 -21 -30 -50
-12 -50 5 0 7 6 4 13 -5 14 22 44 32 34 3 -3 1 -15 -5 -26 -10 -19 -9 -20 11
-12 18 7 23 16 23 46 0 20 -3 39 -7 42 -5 2 -8 -3 -8 -11z"/>
					<path d="M2306 1645 c-4 -19 -2 -43 3 -53 12 -20 12 -21 17 41 5 54 -11 64
-20 12z"/>
					<path d="M2345 1670 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
					<path d="M2391 1653 c-1 -17 -4 -38 -7 -47 -3 -9 -2 -16 4 -16 15 0 23 45 13
72 -9 22 -10 21 -10 -9z"/>
					<path d="M2489 1640 c-6 -45 -4 -58 7 -48 3 3 4 26 2 49 l-3 44 -6 -45z" />
					<path d="M2534 1635 c1 -47 10 -59 19 -26 6 24 -3 71 -13 71 -4 0 -7 -20 -6
-45z"/>
					<path d="M2590 1656 c0 -14 5 -28 10 -31 6 -3 10 5 10 19 0 14 -4 28 -10 31
-6 3 -10 -5 -10 -19z"/>
					<path d="M2797 1643 c-8 -46 2 -62 19 -32 17 31 17 56 0 62 -9 4 -15 -5 -19
-30z"/>
					<path d="M3010 1666 c0 -21 21 -76 30 -76 4 0 11 11 14 25 4 14 11 25 16 25 5
0 12 -11 16 -25 9 -35 27 -31 42 8 10 29 10 30 -3 13 -14 -19 -15 -18 -26 12
-13 39 -22 40 -37 6 -13 -28 -32 -24 -32 7 0 10 -4 19 -10 19 -5 0 -10 -6 -10
-14z"/>
					<path d="M3337 1638 c-3 -23 -4 -45 -1 -47 9 -10 23 16 26 48 4 50 -18 50 -25
-1z"/>
					<path d="M3386 1669 c4 -7 7 -28 6 -46 -2 -19 2 -33 8 -33 17 0 8 82 -9 88 -9
3 -11 0 -5 -9z"/>
					<path d="M2442 1615 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
					<path d="M2708 1562 c-3 -12 -21 -29 -40 -37 -30 -13 -31 -14 -8 -14 36 0 62
23 58 51 l-3 23 -7 -23z"/>
					<path d="M1733 1476 c6 -15 -22 -46 -41 -46 -17 0 -15 -85 1 -99 8 -6 18 -7
22 -3 5 4 6 2 2 -3 -10 -18 6 -33 38 -36 55 -4 74 1 80 22 4 12 12 18 21 15 9
-4 18 4 24 20 17 45 12 87 -15 117 -22 23 -34 27 -81 27 -44 0 -55 -3 -51 -14z
m47 -11 c0 -8 -7 -15 -15 -15 -16 0 -20 12 -8 23 11 12 23 8 23 -8z m30 0 c0
-8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m58
-43 c-2 -9 -8 -17 -15 -19 -7 -3 -10 3 -6 15 2 11 0 23 -6 26 -6 4 -11 15 -11
24 1 14 5 12 21 -7 11 -13 19 -31 17 -39z m-92 1 c-3 -21 -31 -29 -41 -12 -4
6 -1 15 6 20 22 14 37 10 35 -8z m39 9 c18 -13 15 -32 -5 -32 -13 0 -20 7 -20
20 0 21 8 25 25 12z m-95 -22 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10
10 16 10 5 0 9 -4 9 -10z m50 -47 c0 -7 -7 -13 -15 -13 -8 0 -15 6 -15 13 0 8
7 14 15 14 8 0 15 -6 15 -14z m54 2 c-4 -8 -12 -15 -20 -15 -8 0 -14 7 -14 15
0 8 9 15 20 15 13 0 18 -5 14 -15z"/>
					<path d="M1705 1469 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
					<path d="M2875 1400 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
					<path d="M2010 1395 c0 -12 24 -55 32 -55 11 0 10 4 -4 35 -9 21 -28 35 -28
20z"/>
					<path d="M2064 1388 c-4 -7 -3 -8 4 -4 8 4 16 -3 22 -20 13 -33 23 -26 14 10
-6 27 -28 35 -40 14z"/>
					<path d="M2124 1388 c-4 -6 -2 -14 4 -16 7 -2 12 -10 12 -18 0 -8 4 -14 9 -14
6 0 6 12 0 30 -12 33 -15 35 -25 18z"/>
					<path d="M2193 1375 c13 -27 33 -35 23 -9 -3 9 -6 20 -6 25 0 5 -6 9 -14 9
-11 0 -12 -5 -3 -25z"/>
					<path d="M2234 1388 c3 -7 9 -21 12 -30 4 -10 10 -18 14 -18 5 0 3 14 -3 30
-6 17 -15 30 -20 30 -5 0 -6 -6 -3 -12z"/>
					<path d="M2375 1389 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z" />
					<path d="M2480 1380 c0 -11 2 -20 4 -20 2 0 6 9 9 20 3 11 1 20 -4 20 -5 0 -9
-9 -9 -20z"/>
					<path d="M2542 1375 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z" />
					<path d="M2568 1393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M2676 1385 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
					<path d="M2722 1380 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z" />
					<path d="M2820 1389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
					<path d="M3176 1385 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
					<path d="M3216 1391 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
					<path d="M3245 1383 c3 -8 5 -20 5 -26 0 -7 4 -6 10 3 5 8 15 15 21 15 7 0 14
5 16 12 3 7 -7 11 -27 11 -22 -1 -29 -5 -25 -15z"/>
					<path d="M2341 1374 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
